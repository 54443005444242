import React from "react";
import NewsArticleLayout from "../../../components/updated-article/NewsArticleLayout.component";

const isNew = true;
const href = "/ratesheet/20240116-Rate-Sheet.pdf";
const date = "16.01.2023";

const Article = () => (
  <NewsArticleLayout href={href} date={date} isNew={isNew}>
    <h1 className="page-title">Changes to our mortgage products</h1>

    <br />
    <p>With effect from Tuesday 16 January 2024 we will be making the following changes to our range:</p>
    <p>We have increased our cashback incentive offering for First Time Buyer customers at 95% LTV from &pound;250, &pound;350 and &pound;500 across two year fixed, three year fixed and five year fixed rates to &pound;750, &pound;750 and &pound;1000 until further notice.</p>
    <p>All of our fixed rate end dates will be extended as below:</p>
    <ul>
    <li>2 Year Fixed &ndash; 30 April 2026</li>
    <li>3 Year Fixed &ndash; 30 April 2027</li>
    <li>5 Year Fixed &ndash; 30 April 2029</li>
    <li>10 Year Fixed - 30 April 2034</li>
    </ul>
    <br />
    <p><strong>Summary of changes</strong></p>
    <p><strong>UK Residential Existing Customer Switching Range</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 90% and 95% LTV have decreased</li>
      <li>2 Year Fixed Standard at 60%, 80%, 85% and 90% LTV have decreased</li>
      <li>3 Year Fixed Fee Saver at 60%, 90% and 95% LTV have decreased</li>
      <li>3 Year Fixed Standard at 60%, 80%, 85% and 90% LTV have decreased</li>
      <li>5 Year Fixed Fee Saver at 60%, 80%, 85%, 90% and 95% LTV have decreased</li>
      <li>5 Year Fixed Standard at 60%, 80%, 85% and 90% LTV have decreased</li>
      <li>5 Year Fixed Premier Exclusive at 60%, 80%, 85% and 90% LTV have decreased</li>
    </ul>
    <p><strong>UK Residential Existing Customer Borrowing More Range</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60% and 90% LTV have decreased</li>
      <li>2 Year Fixed Standard at 60%, 80%, 85% and 90% LTV have decreased</li>
      <li>3 Year Fixed Fee Saver at 60% and 90% LTV have decreased</li>
      <li>3 Year Fixed Standard at 60%, 80%, 85% and 90% LTV have decreased</li>
      <li>5 Year Fixed Fee Saver at 60%, 80%, 85% and 90% LTV have decreased</li>
      <li>5 Year Fixed Standard at 60%, 80%, 85% and 90% LTV have decreased</li>
      <li>5 Year Fixed Premier Exclusive at 60%, 80%, 85% and 90% LTV have decreased</li>
    </ul>
    <p><strong>UK Residential First Time Buyer Range</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 90% and 95% LTV have decreased</li>
      <li>2 Year Fixed Standard at 80%, 85% and 90% LTV have decreased</li>
      <li>3 Year Fixed Fee Saver at 90% and 95% LTV have decreased</li>
      <li>3 Year Fixed Standard at 80%, 85% and 90% LTV have decreased</li>
      <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have decreased</li>
      <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
      <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
      <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
      <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
    </ul>
    <p><strong>UK Residential Home Mover Range</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 90% and 95% LTV have decreased</li>
      <li>2 Year Fixed Standard at 80%, 85% and 90% LTV have decreased</li>
      <li>3 Year Fixed Fee Saver at 90% and 95% LTV have decreased</li>
      <li>3 Year Fixed Standard at 80%, 85% and 90% LTV have decreased</li>
      <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have decreased</li>
      <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
      <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
    </ul>
    <p><strong>International Residential Range</strong></p>
    <ul>
      <li>5 Year Fixed Fee Saver at 60%, 70% and 75% LTV have decreased</li>
      <li>5 Year Fixed Standard at 60%, 70% and 75% LTV have decreased</li>
      <li>5 Year Fixed Premier Exclusive at 60%, 70% and 75% LTV have decreased</li>
    </ul>
    <p>There are no changes to any other interest rates at this time.</p>
    <p>An updated mortgage rate sheet will be published on Tuesday 16 January 2024 to reflect these changes.</p>
  </NewsArticleLayout>
);

export default Article;
